import { render, staticRenderFns } from "./advisoryDetailts.vue?vue&type=template&id=7aff031a&scoped=true"
var script = {}
import style0 from "./advisoryDetailts.vue?vue&type=style&index=0&id=7aff031a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aff031a",
  null
  
)

export default component.exports